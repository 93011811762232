@import '../colors';

/* Pilot */
.pilot {
  #nav {
    ul {
      background: #144da7;
      width: auto;
      a.is-active,
      a:hover,
      li:hover a.submenu,
      li:hover span.submenu,
      ul.submenu a.is-active,
      ul.submenu a:hover {
        background: $cta-blue;
      }
    }
    #menutoggle {
      background: $cta-blue;
      &:hover,
      &:active,
      &.is-active {
        background-color: lighten($cta-blue, 7.5%);
      }
    }
  }
  #header {
    a.logo {
      max-width: 350px;
      img {
        max-width: 100px;
        margin-right: 15px;
      }
    }
  }
  h1,
  h2,
  h3,
  h4 {
    color: #00529b;
  }
  #footer-wrap {
    background: #2e3237;
    p,
    a,
    h2 {
      color: #fff;
    }
  }

  #banner-wrap {
    background: url(/images/pilot/banner-bg.jpg) no-repeat 50% 75%;
    background-size: cover;
  }

  #icons-wrap {
    .border-icon {
      border-bottom: 1px solid #fe0006;
    }
  }

  .cta-btn {
    max-width: 200px;
    min-width: 170px;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 800;
    padding: 10px 15px 8px 15px;
  }
  .dropdown-list {
    width: 100%;
    max-width: 230px;
    min-width: 220px;
    span.dropdown.cta-dropdown {
      min-width: 170px;
      padding: 15px;
      color: #fff;
      font-weight: 800;
      //background: #65af36;
      background: $cta-red;
      border: 0;
      width: 100%;
      height: auto;
      line-height: 1;
      font-size: 16px;
      text-align: left;
      text-transform: uppercase;
      &:active,
      &:hover,
      &.is-active {
        background: darken($cta-red, 7.5%);
      }
    }
    .drop {
      top: 45px;
    }
    &.drop-up .drop {
      top: unset;
      bottom: 45px;
    }
  }

  .price-border {
    border-bottom: 2px solid #5092ff;
  }

  .border-top-red {
    border-top: 2px solid #fd000d;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    animation: fadeIn 0.15s;
  }

  #insurance-wrap {
    img {
      max-height: 35px;
      width: auto;
    }
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .pilot {
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .pilot {
    #icons-wrap {
      .border-icon {
        border-right: 1px solid #fe0006;
        border-bottom: 0;
        // &.box-2 {
        //   border-right: 0;
        // }
        // &.box-3 {
        //   border-bottom: 0;
        // }
      }
    }
    #insurance-wrap {
      img {
        max-height: none;
      }
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  .pilot {
    #header-wrap {
      border-top: 0;
    }
    #header a.logo span.big {
      font-size: 1.4em;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .pilot {
    #nav {
      float: right;
      height: 40px;
      width: auto;
      background: none;
      clear: none;
      padding: 15px 0;
      a {
        color: #555;
        text-shadow: 0 0 0;
        font-weight: 600;
        &:hover,
        &.is-active {
          color: #fff;
          background: $cta-blue;
        }
        &.livechat:before {
          display: inline-block;
          margin-right: 5px;
          width: inherit;
          height: inherit;
          content: '\e830';
          color: inherit;
          font-family: 'icons';
          font-weight: normal;
          text-align: center;
          font-size: 105%;
          vertical-align: top;
        }
      }
    }

    #header-wrap {
      border-top: 0;
      border-bottom: 1px solid #ccc;
    }
    #nav {
      a {
        padding: 0px 8px;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .pilot {
    #icons-wrap {
      .border-icon {
        // border-bottom: 0;
        // &.box-2 {
        //   border-right: 1px solid #fe0006;
        // }
      }
    }
  }
}
