/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* H2D */
/* RW */
/* DIP */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Text
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #0c2d63 !important;
}

.text-theme-secondary {
  color: #243f86 !important;
}

/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: #0c2d63;
}

.bg-theme-primary-dark {
  background-color: #333942;
}

.bg-theme-secondary {
  background-color: #243f86;
}

/* ----------------------------------------------------------------------------
    Borders
----------------------------------------------------------------------------- */
/* Pilot */
.pilot #nav ul {
  background: #144da7;
  width: auto;
}
.pilot #nav ul a.is-active,
.pilot #nav ul a:hover,
.pilot #nav ul li:hover a.submenu,
.pilot #nav ul li:hover span.submenu,
.pilot #nav ul ul.submenu a.is-active,
.pilot #nav ul ul.submenu a:hover {
  background: #103d85;
}
.pilot #nav #menutoggle {
  background: #103d85;
}
.pilot #nav #menutoggle:hover, .pilot #nav #menutoggle:active, .pilot #nav #menutoggle.is-active {
  background-color: #144da7;
}
.pilot #header a.logo {
  max-width: 350px;
}
.pilot #header a.logo img {
  max-width: 100px;
  margin-right: 15px;
}
.pilot h1,
.pilot h2,
.pilot h3,
.pilot h4 {
  color: #00529b;
}
.pilot #footer-wrap {
  background: #2e3237;
}
.pilot #footer-wrap p,
.pilot #footer-wrap a,
.pilot #footer-wrap h2 {
  color: #fff;
}
.pilot #banner-wrap {
  background: url(/images/pilot/banner-bg.jpg) no-repeat 50% 75%;
  background-size: cover;
}
.pilot #icons-wrap .border-icon {
  border-bottom: 1px solid #fe0006;
}
.pilot .cta-btn {
  max-width: 200px;
  min-width: 170px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 800;
  padding: 10px 15px 8px 15px;
}
.pilot .dropdown-list {
  width: 100%;
  max-width: 230px;
  min-width: 220px;
}
.pilot .dropdown-list span.dropdown.cta-dropdown {
  min-width: 170px;
  padding: 15px;
  color: #fff;
  font-weight: 800;
  background: #e62339;
  border: 0;
  width: 100%;
  height: auto;
  line-height: 1;
  font-size: 16px;
  text-align: left;
  text-transform: uppercase;
}
.pilot .dropdown-list span.dropdown.cta-dropdown:active, .pilot .dropdown-list span.dropdown.cta-dropdown:hover, .pilot .dropdown-list span.dropdown.cta-dropdown.is-active {
  background: #cc172b;
}
.pilot .dropdown-list .drop {
  top: 45px;
}
.pilot .dropdown-list.drop-up .drop {
  top: unset;
  bottom: 45px;
}
.pilot .price-border {
  border-bottom: 2px solid #5092ff;
}
.pilot .border-top-red {
  border-top: 2px solid #fd000d;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.pilot .sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation: fadeIn 0.15s;
}
.pilot #insurance-wrap img {
  max-height: 35px;
  width: auto;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 768px) {
  .pilot #icons-wrap .border-icon {
    border-right: 1px solid #fe0006;
    border-bottom: 0;
  }
  .pilot #insurance-wrap img {
    max-height: none;
  }
}
@media only screen and (min-width: 56.25em) {
  .pilot #header-wrap {
    border-top: 0;
  }
  .pilot #header a.logo span.big {
    font-size: 1.4em;
  }
}
@media (min-width: 992px) {
  .pilot #nav {
    float: right;
    height: 40px;
    width: auto;
    background: none;
    clear: none;
    padding: 15px 0;
  }
  .pilot #nav a {
    color: #555;
    text-shadow: 0 0 0;
    font-weight: 600;
  }
  .pilot #nav a:hover, .pilot #nav a.is-active {
    color: #fff;
    background: #103d85;
  }
  .pilot #nav a.livechat:before {
    display: inline-block;
    margin-right: 5px;
    width: inherit;
    height: inherit;
    content: "\e830";
    color: inherit;
    font-family: "icons";
    font-weight: normal;
    text-align: center;
    font-size: 105%;
    vertical-align: top;
  }
  .pilot #header-wrap {
    border-top: 0;
    border-bottom: 1px solid #ccc;
  }
  .pilot #nav a {
    padding: 0px 8px;
  }
}